
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import TypeModal from '@/components/modals/forms/program/TrainingTargretModel.vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export default defineComponent({
  name: 'Course Type List',
  components: {
    Datatable,
    TypeModal,
  },
  data() {
    return {
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '120px',
        },
        {
          name: 'Entity Name',
          key: 'entity_name',
          sortable: true,
        },
        {
          name: 'Tranche',
          key: 'tranche',
          sortable: true,
        },
        {
          name: 'Year',
          key: 'year',
          sortable: true,
        },
        {
          name: 'Target Trainee Number',
          key: 'trainee_number',
          sortable: true,
        },
      ],
      lists: [],
      search: '',
      tableData: [],
      data: {},
      entityTypes: [],
    };
  },
  async created() {
    await this.getData();
    Object.assign(this.tableData, this.lists);
    this.emitter.on('type-updated', async () => {
      await this.getData();
      Object.assign(this.tableData, this.lists);
    });
  },
  methods: {
    async getData() {
      await ApiService.get('entity/types')
        .then((response) => {
          this.lists = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    searchItems() {
      if (this.search !== '') {
        let results: never[] = [];
        for (let j = 0; j < this.tableData.length; j++) {
          if (this.searchingFunc(this.tableData[j], this.search)) {
            results.push(this.tableData[j]);
          }
        }
        this.tableData.splice(0, this.tableData.length, ...results);
      } else {
        Object.assign(this.tableData, this.lists);
      }
    },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === 'object')) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    },

    edit(data) {
      this.emitter.emit('edit-modal-data', data);
    },

    add() {
      this.emitter.emit('add-modal-data', true);
    },

    view(industry) {
      this.data = industry;
    },

    Delete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('entity/types/' + `${id}`)
            .then((response) => {
              this.emitter.emit('type-updated', true);
              Swal.fire('Deleted!', response.data.message, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
